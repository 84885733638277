// TODO: use js-cookie instead of DIY

// Regex from here:
// eslint-disable-next-line
// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
// Maintains life cycle of a single cookie.
export default (name, expire, asJSON, path = '/') => ({
  get() {
    const s = decodeURIComponent(
      document.cookie.replace(
        new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(
          name,
        ).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`),
        '$1',
      ),
    ) || null;
    return asJSON ? JSON.parse(s) : s;
  },

  set(value) {
    let d;
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
      asJSON ? JSON.stringify(value) : String(value),
    )}`;
    if (expire) {
      d = new Date();
      d.setTime(parseInt(expire, 10) * 86400000 + d.getTime());
      cookie += `;expires=${d.toUTCString()}`;
    }
    document.cookie = `${cookie};path=${path}`;
  },

  exists() {
    return (
      new RegExp(`(?:^|;\\s*)${encodeURIComponent(
        name,
      ).replace(/[-.+*]/g, '\\$&')}\\s*\\=`)
    ).test(document.cookie);
  },

  remove() {
    if (this.exists(name)) {
      this.set(name, '', -365);
      return true;
    }
    return false;
  },
});
