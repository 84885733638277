import SGCookie from './SGCookie';

// Check for tracking token in all URL fragments
// Run early in attempt to avoid potential fragment conflict with other code

window.SG = window.SG || {};
window.SG.Track = window.SG.Track || {};

window.SG.Track.Token = (() => {
  let hash;
  let token = '';
  let tokenIdAt = -1;
  const settings = {};
  let cookie;

  function getHash() {
  // get consistent hash
    const { href } = window.location;
    const poundCharAt = href.indexOf('#');
    return poundCharAt < 0 ? '' : href.substr(poundCharAt);
  }

  function setHash(value) {
    // No naked hash
    const _hash = value === '#' ? '' : value;

    const p = window.location.pathname + window.location.search + _hash;
    try {
      window.history.replaceState('', document.title, p);
    } catch (e) {
      // This may trigger unwanted hashchange events. TODO: see if way to block
      window.location.replace(p);
    }
  }

  function cleanHash() {
    if (tokenIdAt >= 0) {
      setHash(hash.substr(0, tokenIdAt));
    }
  }

  function setToken(_token) {
    cookie.set(_token);
  }

  function getToken() {
    return cookie.get();
  }

  function parseToken() {
    // Set the token if it's new, otherwise return the existing token
    const existing = getToken();
    if (existing) {
      cleanHash();
      return existing;
    }
    hash = getHash();
    tokenIdAt = hash.lastIndexOf(settings.id);
    token = tokenIdAt < 0 ? '' : hash.substr(tokenIdAt + settings.id.length);

    if (token) {
      setToken(token);
      cleanHash();
    }

    return token;
  }

  function init(_settings) {
    Object.assign(settings, _settings);
    cookie = SGCookie(settings.cookie.name, settings.expiryDays);
    parseToken();
  }

  return {
    set: setToken,
    get: getToken,
    init,
  };
})();

window.SG.Track.Token.init(window.SGData.Track.Token);
